import styled from '@emotion/styled'
import type { CustomerContractDescription } from '@orus.eu/backend/src/views/customer-contract-view'
import type { UserAccount } from '@orus.eu/backend/src/views/user-account-view'
import {
  Avatar,
  Button,
  ContentContainerBackoffice,
  Divider,
  FlexColumn,
  FlexRow,
  FlexSpacedColumn,
  LegacyDialog,
  PersistentNotification,
  Text,
  TextFieldFormField,
  colors,
  spacing,
  useAsyncCallback,
  useDialogVisibility,
  useEnqueueToast,
} from '@orus.eu/pharaoh'
import { Fragment, memo, useCallback, useReducer } from 'react'
import { v4 } from 'uuid'
import { trpc } from '../../../../client'
import { BackofficeSectionTitle } from '../../../atoms/backoffice-section-title'
import { ActivableProductInformationIdsBadgesList } from '../../../molecules/activable-product-information-ids-badges-list'

type UsersAccountsCardVariant = 'main' | 'other'

type ErrorGettingUserDataType = 'user-not-found' | 'user-not-customer' | 'user-already-loaded' | undefined

type UserSectionState = {
  id: string
  email: string
  userData:
    | {
        userAccount: UserAccount
        userContracts: CustomerContractDescription[]
      }
    | undefined
  errorGettingUserData: ErrorGettingUserDataType
}

type MergedToolState = {
  mainUser: UserSectionState
  otherUsers: UserSectionState[]
}

type MergeToolAction =
  | {
      type: 'add-user'
    }
  | {
      type: 'delete-user'
      userInfoId: string
    }
  | {
      type: 'update-email'
      userInfoId: string
      email: string
    }
  | {
      type: 'user-data-loaded'
      requestedEmail: string
      userInfoId: string
      userAccount: UserAccount
      userContracts: CustomerContractDescription[]
    }
  | {
      type: 'user-data-loading-failed'
      requestedEmail: string
      userInfoId: string
      errorGettingUserData: ErrorGettingUserDataType
    }
  | {
      type: 'reset'
    }

function newLocalId() {
  return `local-${v4()}`
}

const intialState: MergedToolState = {
  mainUser: {
    id: newLocalId(),
    email: '',
    userData: undefined,
    errorGettingUserData: undefined,
  },
  otherUsers: [
    {
      id: newLocalId(),
      email: '',
      userData: undefined,
      errorGettingUserData: undefined,
    },
  ],
}

const mergeToolStateHas = (state: MergedToolState, email: string) => {
  return [state.mainUser.email, ...state.otherUsers.map((user) => user.email)].includes(email)
}

const reducer = (state: MergedToolState, action: MergeToolAction) => {
  switch (action.type) {
    case 'add-user':
      return {
        ...state,
        otherUsers: [
          ...state.otherUsers,
          {
            id: newLocalId(),
            email: '',
            userData: undefined,
            errorGettingUserData: undefined,
          },
        ],
      }
    case 'delete-user':
      if (action.userInfoId === state.mainUser.id) {
        return {
          ...state,
          mainUser: {
            ...state.mainUser,
            userData: undefined,
            email: '',
          },
        }
      } else {
        return {
          ...state,
          otherUsers: state.otherUsers.filter((user) => user.id !== action.userInfoId),
        }
      }
    case 'update-email':
      if (action.userInfoId === state.mainUser.id) {
        return {
          ...state,
          mainUser: {
            ...state.mainUser,
            email: action.email,
          },
        }
      } else {
        const index = state.otherUsers.findIndex((user) => user.id === action.userInfoId)
        if (index === -1) {
          return state
        } else {
          return {
            ...state,
            otherUsers: state.otherUsers.map((user, i) => (i !== index ? user : { ...user, email: action.email })),
          }
        }
      }
    case 'user-data-loaded':
      if (action.userInfoId === state.mainUser.id) {
        if (action.requestedEmail !== state.mainUser.email) {
          return state
        }
        return {
          ...state,
          mainUser: {
            ...state.mainUser,
            userData: {
              userAccount: action.userAccount,
              userContracts: action.userContracts,
            },
            errorGettingUserData: undefined,
          },
        }
      } else {
        const index = state.otherUsers.findIndex((user) => user.id === action.userInfoId)
        if (index === -1 || action.requestedEmail !== state.otherUsers[index].email) {
          return state
        } else {
          return {
            ...state,
            otherUsers: state.otherUsers.map((user, i) =>
              i !== index
                ? user
                : {
                    ...user,
                    userData: {
                      userAccount: action.userAccount,
                      userContracts: action.userContracts,
                    },
                    errorGettingUserData: undefined,
                  },
            ),
          }
        }
      }

    case 'user-data-loading-failed':
      if (action.userInfoId === state.mainUser.id) {
        if (action.requestedEmail !== state.mainUser.email) {
          return state
        }
        return {
          ...state,
          mainUser: {
            ...state.mainUser,
            userData: undefined,
            errorGettingUserData: action.errorGettingUserData,
          },
        }
      } else {
        const index = state.otherUsers.findIndex((user) => user.id === action.userInfoId)
        if (index === -1) {
          return state
        } else {
          if (action.requestedEmail !== state.otherUsers[index].email) {
            return state
          }
          return {
            ...state,
            otherUsers: state.otherUsers.map((user, i) =>
              i !== index ? user : { ...user, userData: undefined, errorGettingUserData: action.errorGettingUserData },
            ),
          }
        }
      }

    case 'reset':
      return intialState
    default:
      return state
  }
}

const PlatformMigrationPage = memo(function PlatformMigrationPage() {
  const [state, dispatch] = useReducer(reducer, intialState)

  const { enqueueToast } = useEnqueueToast()

  const handleEmailChange = useAsyncCallback(
    async (userInfoId: string, email: string) => {
      dispatch({ type: 'update-email', userInfoId, email })
      if (mergeToolStateHas(state, email)) {
        dispatch({
          type: 'user-data-loading-failed',
          userInfoId,
          requestedEmail: email,
          errorGettingUserData: 'user-already-loaded',
        })
        return
      }
      const userAccount = await trpc.users.getUserByEmail.query(email)
      if (!userAccount) {
        dispatch({
          type: 'user-data-loading-failed',
          userInfoId,
          requestedEmail: email,
          errorGettingUserData: 'user-not-found',
        })
        return
      }

      if (!userAccount.customerNumber) {
        dispatch({
          type: 'user-data-loading-failed',
          userInfoId,
          requestedEmail: email,
          errorGettingUserData: 'user-not-customer',
        })
        return
      }

      const userContracts = await trpc.contracts.listCustomerContracts.query(userAccount?.id || '')
      dispatch({ type: 'user-data-loaded', userInfoId, userAccount, userContracts, requestedEmail: email })
    },
    [state, dispatch],
  )

  const handleAddUser = useCallback(() => {
    dispatch({ type: 'add-user' })
  }, [dispatch])

  const handleDeleteUser = useCallback(
    (userInfoId: string) => {
      dispatch({ type: 'delete-user', userInfoId })
    },
    [dispatch],
  )

  const dialogVisibility = useDialogVisibility('merge-accounts')

  const isCTADisabled =
    !state.mainUser.email ||
    (state.mainUser.email && state.mainUser.errorGettingUserData !== undefined) ||
    state.otherUsers.length === 0 ||
    state.otherUsers.some((user) => user.email && user.errorGettingUserData !== undefined) ||
    state.otherUsers.every((user) => !user.email)

  const mergeAccounts = useAsyncCallback(async () => {
    await trpc.subscriptions.setMultipleSubscriptionsOwner.mutate({
      customerId: state.mainUser.userData?.userAccount.id || '',
      subscriptionIds: state.otherUsers
        .map((user) => user.userData?.userContracts.map((contract) => contract.subscriptionId))
        .map((ids) => ids || [])
        .flat(),
    })
    dialogVisibility.hide()
    dispatch({ type: 'reset' })
    enqueueToast('Les comptes utilisateurs ont été fusionnés avec succès', { variant: 'success' })
  }, [state.mainUser.userData, state.otherUsers, dialogVisibility, dispatch, enqueueToast])

  return (
    <ContentContainerBackoffice>
      <FlexSpacedColumn margin={'0px'} padding={'0px'}>
        <BackofficeSectionTitle>Migration de comptes utilisateurs</BackofficeSectionTitle>

        <UsersAccountsCard variant="main">
          <UserAccountSection
            variant="main"
            user={state.mainUser}
            onChangeEmail={handleEmailChange}
            onDeleteUser={handleDeleteUser}
          />
        </UsersAccountsCard>

        <OtherUsersAccounts
          otherUsers={state.otherUsers}
          onChangeEmail={handleEmailChange}
          onDeleteUser={handleDeleteUser}
          onAddUser={handleAddUser}
        />

        <PushedRightButton type="submit" onClick={dialogVisibility.show} disabled={isCTADisabled}>
          Fusionner les comptes
        </PushedRightButton>
      </FlexSpacedColumn>

      {dialogVisibility.visible ? (
        <MergeUserAccountsDialog onClose={dialogVisibility.hide} onValidate={mergeAccounts} />
      ) : (
        <></>
      )}
    </ContentContainerBackoffice>
  )
})

const PushedRightButton = styled(Button)`
  align-self: flex-end;
`

const OtherUsersAccounts = memo<{
  otherUsers: UserSectionState[]
  onChangeEmail: (userInfoId: string, email: string) => void
  onDeleteUser: (userInfoId: string) => void
  onAddUser: () => void
}>(function OtherUsersAccounts({ otherUsers, onChangeEmail, onDeleteUser, onAddUser }) {
  return (
    <UsersAccountsCard variant="other">
      {otherUsers.map((item, index) => (
        <Fragment key={index}>
          <UserAccountSection variant="other" user={item} onChangeEmail={onChangeEmail} onDeleteUser={onDeleteUser} />
          <Divider orientation="horizontal" />
        </Fragment>
      ))}

      <PushedLeftButton
        avatar={<Avatar icon="plus-solid" />}
        avatarPosition="left"
        variant="secondary"
        type="button"
        onClick={onAddUser}
      >
        Ajouter un compte
      </PushedLeftButton>
    </UsersAccountsCard>
  )
})

const PushedLeftButton = styled(Button)`
  align-self: flex-start;
`

const UsersAccountsCard = styled.div<{ variant: UsersAccountsCardVariant }>`
  display: flex;
  flex-direction: column;
  gap: ${spacing[60]};
  border: ${(props) => (props.variant === 'main' ? `2px solid ${colors.blue[500]}` : `1px solid ${colors.gray[100]}`)};
  border-radius: ${spacing[30]};
  padding: ${spacing[60]};
  box-shadow:
    0 2px 3px 0 #00006308,
    0 4px 5px 0 #00006308,
    0 10px 8px 0 #00006305,
    0 16px 11px 0 #00006300,
    0 24px 16px 0 #00006300;
`

const UserAccountSectionContainer = styled.div`
  display: flex;
  gap: ${spacing[60]};

  & > *:not(:last-child) {
    flex: 1 1 0px;
  }
`

const UserAccountSection = memo<{
  variant: UsersAccountsCardVariant
  user: UserSectionState
  onChangeEmail: (userInfoId: string, email: string) => void
  onDeleteUser: (userInfoId: string) => void
}>(function UserAccountSection({ variant, user, onChangeEmail, onDeleteUser }) {
  const textInputLabel = variant === 'main' ? 'Compte utilisateur principal' : 'Autre compte utilisateur'

  return (
    <UserAccountSectionContainer>
      <TextFieldFormField
        size="small"
        label={textInputLabel}
        aria-label={textInputLabel}
        value={user.email}
        placeholder="Email"
        onChange={(event) => onChangeEmail(user.id, event.target.value)}
        error={user.errorGettingUserData !== undefined}
      />

      <UserAccountInformations
        variant={variant}
        userAccount={user.userData?.userAccount}
        userContracts={user.userData?.userContracts}
        errorGettingUserData={user.errorGettingUserData}
      />
      <Button
        variant="secondary"
        size="small"
        type="submit"
        avatar={<Avatar icon="xmark-solid" />}
        onClick={() => onDeleteUser(user.id)}
      />
    </UserAccountSectionContainer>
  )
})

const UserAccountInformations = memo<{
  variant: UsersAccountsCardVariant
  userAccount: UserAccount | undefined
  userContracts: CustomerContractDescription[] | undefined
  errorGettingUserData: ErrorGettingUserDataType
}>(function UserAccountInformations({ variant, userAccount, userContracts, errorGettingUserData }) {
  return (
    <FlexColumn gap={spacing[50]} padding={'0px'} margin={'0px'}>
      {variant === 'main' && !userAccount ? (
        <Text variant="body2">
          Tous les contrats des autres comptes seront attribués au compte principal. Les autres comptes seront ensuite
          vides.
        </Text>
      ) : null}
      {userAccount ? (
        <>
          <FlexColumn gap={'0px'} padding={'0px'} margin={'0px'}>
            <Text variant="body2Medium">{`${userAccount.firstName} ${userAccount.lastName}`}</Text>
            <Text variant="body2Medium">{userAccount.phone}</Text>
          </FlexColumn>
          <FlexColumn gap={'0px'} padding={'0px'} margin={'0px'}>
            <Text variant="body2">Numéro client : {userAccount.customerNumber}</Text>
          </FlexColumn>
          {userContracts
            ? userContracts.map((contract, index) => (
                <FlexRow key={index} gap={spacing[50]} padding={'0px'} margin={'0px'}>
                  <Text key={contract.subscriptionId} variant="body2">
                    {contract.contractNumber}
                  </Text>
                  <ActivableProductInformationIdsBadgesList
                    activableProductInformationIds={contract.activableProductInformationIds}
                  />
                </FlexRow>
              ))
            : null}
        </>
      ) : errorGettingUserData ? (
        errorGettingUserDataNotification(errorGettingUserData)
      ) : null}
    </FlexColumn>
  )
})

const errorGettingUserDataNotification = (errorGettingUserData: ErrorGettingUserDataType) => {
  switch (errorGettingUserData) {
    case 'user-not-found':
      return (
        <PersistentNotification variant="warning" title="Email inconnu">
          Nous avons un problème avec cette adresse email, elle n’est pas présente dans la base client.
        </PersistentNotification>
      )
    case 'user-not-customer':
      return (
        <PersistentNotification variant="warning" title="Cet email n'appartient pas à un client">
          Cette addresse est celle d‘un utilisateur ayant accès au back-office, ils ne peuvent pas avoir de contrats.
        </PersistentNotification>
      )
    case 'user-already-loaded':
      return (
        <PersistentNotification variant="warning" title="Email déjà chargé">
          Cette adresse email a déjà été chargée dans le formulaire.
        </PersistentNotification>
      )
    default:
      return null
  }
}

type MergeUserAccountsDialogProps = {
  onClose: () => void
  onValidate: () => void
}

function MergeUserAccountsDialog(props: MergeUserAccountsDialogProps): JSX.Element {
  const { onClose, onValidate } = props

  const handleSubmit = useCallback(() => {
    onValidate()
  }, [onValidate])

  return (
    <LegacyDialog
      variant="backoffice"
      style="danger"
      title="Des comptes utilisateurs vont être écrasés"
      onClose={onClose}
      onSubmit={handleSubmit}
      submitLabel="Lancer la migration"
      secondaryActionLabel="Annuler"
      onSecondaryAction={onClose}
      maxWidth="xs"
    >
      <Text>Êtes-vous certain de vouloir migrer et fusionner l’ensemble de ces comptes utilisateurs ?</Text>
    </LegacyDialog>
  )
}

export default PlatformMigrationPage
